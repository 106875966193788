import React from "react";
import { Auth } from "aws-amplify";

export default function AuthenticatedRoute({ component: C, isAuthenticated }) {
  console.log('isAuthenticated: ', isAuthenticated)

  if (isAuthenticated) {
    return <C />
  } else {
    console.log('user needs auth')
    Auth.federatedSignIn()
    return null
  }
}
