import React from 'react';
import {Helmet} from "react-helmet";
// import opto_ss from '../../assets/images/opto_screenshot_1000_width.png';
// import bball_pic from './bball-on-court.jpg';
// import bball_pic from '../../assets/images/bball_court_gray.jpg';
import bball_pic from '../../assets/images/basketball-court-wood-dimmed.jpg';

function Home() {

  return (
    <div className="container-fluid">

      <Helmet>
        {/* Specify what the preferred URL is, to handle capitalization, query strings, etc. */}
        <link rel="canonical" href={`https://bluecollardfs.com/`} />
      </Helmet>

      <div className="" style={{background: `url(${bball_pic}) no-repeat center center`, backgroundSize: 'cover'}}>
        <div className="d-flex flex-column" style={{minHeight: '65vh'}}>

          <div className="text-center text-white my-auto">
            <h1 className="text-uppercase mb-2">Blue Collar DFS</h1>
            <h2 className="mb-2">Let's win together</h2>
            <p className="mb-0">We are passionate sports fans making helpful tools widely available at a low cost</p>
          </div>

        </div>
      </div>

      <section className="bg-white">

        <div className="container text-center py-5">
          <h2 className="section-heading">How We Can Help</h2>


          <div className="row">

            <div className="col-lg-3 col-md-6 text-center">
              <div className="service-box mt-5 mx-auto">
                <span className="fas fa-4x fa-calendar mb-3"></span>
                <h3 className="mb-3">Save Time</h3>
                <p className="text-muted mb-0">Streamlined lineup generation after a busy day</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 text-center">
              <div className="service-box mt-5 mx-auto">
                <span className="fas fa-4x fa-bullseye mb-3"></span>
                <h3 className="mb-3">Accurate Analytics</h3>
                <p className="text-muted mb-0">Your sports brain + our math = you win</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 text-center">
              <div className="service-box mt-5 mx-auto">
                <span className="fas fa-4x fa-clock mb-3"></span>
                <h3 className="mb-3">Up to Date</h3>
                <p className="text-muted mb-0">Projections updated throughout the day for injuries and news</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 text-center">
              <div className="service-box mt-5 mx-auto">
                <span className="fas fa-4x fa-child mb-3"></span>
                <h3 className="mb-3">Made by Fans</h3>
                <p className="text-muted mb-0">We love sports as much as you do, let's succeed together!</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white">
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-8 mx-auto text-center">
              <hr />
              <h2 className="section-heading mb-4">DFS tools that you need!</h2>
              <p>
                We leverage data to generate projections, display that data
                in a user-friendly way, and use the projection data to
                help you win.
              </p>
              <p>
                DFS sites are increasing their income on each contest (rake), why
                also pay a large amount for fantasy tools?
              </p>
              <p>
                Engineers by day, sports obsessed by night.
              </p>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default Home;
