import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom'
import Amplify from 'aws-amplify';
import {Helmet} from "react-helmet";
import "@popperjs/core";
import "bootstrap"

// import * as bootstrap from 'bootstrap';
// import * as serviceWorker from './serviceWorker';

// https://aws-amplify.github.io/docs/js/authentication#manual-setup
Amplify.configure({
  Auth: {

    // Amazon Cognito Region
    region: "us-east-1",

    // Amazon Cognito User Pool ID
    userPoolId: "us-east-1_1iq768vIW",

    // Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "1ak3ia9qsjns24ssuvjdgpos3o",

    // Enforce user authentication prior to accessing AWS resources or not
    // mandatorySignIn: true,

    // Hosted UI configuration
    oauth: {
      domain: 'auth.bluecollardfs.com',
      // scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      scope: ['email', 'openid'],
      // redirectSignIn: 'http://localhost:3000/',
      // redirectSignOut: 'http://localhost:3000/',
      redirectSignIn: 'https://bluecollardfs.com/',
      redirectSignOut: 'https://bluecollardfs.com/',
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    },

    // federationTarget: "COGNITO_USER_POOLS"
  }
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Helmet>
        <title>Bluecollar DFS | Daily Fantasy Sports Tools For Everyone</title>
        <meta name="description" content="BluecollarDFS provides free projections and optimizers to help daily fantasy players build lineups to win contests." />
      </Helmet>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
