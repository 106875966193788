import React from 'react';
import './Footer.css'

function Footer() {

  return (
    <footer className="footer mt-auto py-3 bg-dark">
      <div className="container-fluid">

        <div className="row justify-content-between">

          <div className="col-6 col-sm-3 mb-4 mb-sm-0">
            <h6 className="text-white mb-3">COMPANY</h6>

            {/* List Group */}
            <div className="list-group list-group-transparent">
            <a className="list-group-item list-group-item-action px-0 py-1" href="/plans">Plans</a>
              <a className="list-group-item list-group-item-action px-0 py-1" href="/about">About</a>
              <a className="list-group-item list-group-item-action px-0 py-1" href="/privacy_policy">Privacy</a>
              <a className="list-group-item list-group-item-action px-0 py-1" href="/contact">Contact</a>
            </div>
          </div>

          <div className="col-6 col-sm-3 mb-4 mb-sm-0">
            <h6 className="text-white mb-3">TOOLS</h6>

            {/* List Group */}
            <div className="list-group list-group-transparent">
              <a className="list-group-item list-group-item-action px-0 py-1" href="/nba-optimizer">NBA Optimizer</a>
              <a className="list-group-item list-group-item-action px-0 py-1" href="/mlb-optimizer">MLB Optimizer</a>
              <a className="list-group-item list-group-item-action px-0 py-1" href="/nfl-optimizer">NFL Optimizer</a>
            </div>
          </div>

          <div className="col-6 col-sm-3 mb-4 mb-sm-0">
            <h6 className="text-white mb-3">ARTICLES</h6>

            {/* List Group */}
            <div className="list-group list-group-transparent">
              <a className="list-group-item list-group-item-action px-0 py-1" href="/dfs_intro">DFS Introduction</a>
            </div>
          </div>

          <div className="col-6 col-sm-3 mb-4 mb-sm-0">
            <h6 className="text-white mb-3">LINKS</h6>

            <div className="list-group list-group-transparent">
              <a className="list-group-item list-group-item-action px-0 py-1" href="https://twitter.com/BlueCollarDFS"><span className="fab fa-twitter"></span> Twitter</a>
              <a className="list-group-item list-group-item-action px-0 py-1" href="/support_us"><span className="iconify fas" data-icon="flat-color-icons:money-transfer" data-inline="false"></span> Donate</a>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bar half-opacity">
        <div className="container">
          <div className="row text-white text-center justify-content-center">
              <p>&copy; 2017-2021 Bluecollar Daily Fantasy Sports. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
