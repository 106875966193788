import React from 'react';
// import './MyNavBar.css'
import { Auth } from "aws-amplify";

import './index.css';

import logo_small from '../../assets/images/fist_320_width.png';
import { useLocation } from 'react-router-dom'

// Inspiration for order / spacing logic
// https://bootstrapbay.com/blog/day-10-bootstrap-4-navbar-tutorial-and-examples/



function NavBar(props) {
  // let history = useHistory()
  let location = useLocation()

  // async function handleLogout() {
  //   await Auth.signOut();
  //   props.userHasAuthenticated(false)
  //   console.log('User has logged out!')
  //   // history.push('/')
  // }

  return (
    // <div className="navbar-dark black-bg fixed-top matching-dropdown">
    // <header className="header header-transparent">
    <header>
      <nav className="navbar navbar-expand-sm fixed-top static-top navbar-dark bg-dark py-0 shadow">
        <div className="container-fluid">

          {/* Menu Button */}
          {/* <div className=""> */}
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navBarCollapse" aria-controls="navBarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          {/* </div> */}
          {/* End Menu Button */}

          {/* Nav */}
          {/* <div id="navBar" className="collapse navbar-collapse w-100 order-0 order-sm-1"> */}
          <div className="collapse navbar-collapse order-1" id="navBarCollapse">

            <ul className="navbar-nav mx-auto">

              {/* NBA */}
              <li className="nav-item mx-1 dropdown">
                  {/* Do not use className dropdown-toggle here to avoid the dropdown carot */}
                <button className="btn nav-link no-click" id="nba-dropdown" href="#"
                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    NBA
                </button>

                <div className="dropdown-menu shadow" aria-labelledby="nba-dropdown">
                  <a className="dropdown-item" href='/nba-optimizer'>Optimizer</a>
                </div>
              </li>

              {/* MLB */}
              <li className="nav-item mx-1 dropdown">
                <button className="btn nav-link no-click" id="mlb-dropdown" href="#"
                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">MLB
                </button>

                <div className="dropdown-menu" aria-labelledby="mlb-dropdown">
                  <a className="dropdown-item" href='/mlb-optimizer'>Optimizer</a>
                </div>
              </li>

              {/* NFL */}
              <li className="nav-item mx-1 dropdown">
                {/* Do not use className dropdown-toggle here to avoid the dropdown carot */}
                <button className="btn nav-link no-click" id="nfl-dropdown" href="#"
                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">NFL
                </button>

                {/* <div className="me-3 fas fa-cogs"> */}
                <div className="dropdown-menu" aria-labelledby="nfl-dropdown">
                  <a className="dropdown-item" href='/nfl-optimizer'>Optimizer</a>
                </div>
              </li>

              {/* GOLF */}
              <li className="nav-item mx-1 dropdown">
                {/* Do not use className dropdown-toggle here to avoid the dropdown carot */}
                <button className="btn nav-link no-click" id="golf-dropdown" href="#"
                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">GOLF
                </button>

                {/* <div className="me-3 fas fa-cogs"> */}
                <div className="dropdown-menu" aria-labelledby="golf-dropdown">
                  <a className="dropdown-item" href='/golf-optimizer'>Optimizer</a>
                </div>
              </li>

            </ul>
          </div>
          {/* End collapse menu items */}


          {/* Brand */}
          <a className="navbar-brand p-0 me-0" href="/" aria-label="BluecollarDFS">
            <img className="" src={logo_small} alt="BluecollarDFS" width='52'/>
          </a>
          {/* End Brand */}

          {/* Login Button */}
          { /* https://getbootstrap.com/docs/4.0/components/buttons/
            When using button classes on <a> elements that are used to trigger in-page functionality
            (like collapsing content), rather than linking to new pages or sections within the current page,
            these links should be given a role="button" to appropriately convey their purpose to assistive
            technologies such as screen readers.
          */}
          {props.isAuthenticated
            ?
              <div className="order-sm-2 d-flex">
                <div className="my-auto p-0 nav-item d-flex">
                  {/* <a className="btn fas fa-2x fa-user-circle user-button" href='/user'></a> */}
                  <a className="btn nav-link fas fa-2x fa-user-circle user-button" href='/user'></a>
                  {/* <button className="btn nav-link login-button" onClick={handleLogout} id="logout-button">LOG OUT</button> */}
                  {/* <button className="btn me-2-lg login-button" onClick={handleLogout} id="logout-button">LOG OUT</button> */}
                </div>
              </div>

            : <div className="order-sm-2">
                <div className="my-auto p-0 nav-item">
                  <button className="btn nav-link login-button" id="login-button" onClick={() => Auth.federatedSignIn({ customState: location.pathname.replace('/', 'slash') })}>LOG IN</button>
                  {/* <a className="btn me-2-lg btn-outline-secondary" id="login-button" href="/login"><span className="fas fa-user-circle me-1"></span>Login</a> */}
                </div>
              </div>
          }
          {/* End Login Button */}
        </div>

      </nav>
    </header>
  )
}

export default NavBar;
