import React, { useState, Suspense, lazy, useEffect } from 'react'
// import './App.css';
import './overall.css';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom'
import ReactGA from "react-ga4";
import NavBar from './components/NavBar'
import { Auth, Hub } from "aws-amplify";
// import { createBrowserHistory } from "history"
import Loading from './components/Loading/Loading'
import Footer from './components/Footer/Footer'
import Home from './routes/Home/Home'
import NotFound from './components/NotFound'

import AuthenticatedRoute from './components/AuthenticatedRoute/AuthenticatedRoute'


// import LoginTest from './containers/LoginTest'

// import TeamStatsNBA from './NBA/TeamStatsNBA'
// import LineupsPage from './MLB/LineupsPage'
// import ProjectionsNumberball from './numberball/Projections';
// import Callback from './Callback/Callback';
// import Auth from './Auth/Auth.js.js';
// import Profile from './Profile/Profile.js.js';

// Pages with text content
// lazy load for route based code splitting per
// https://reactjs.org/docs/code-splitting.html#route-based-code-splitting
const About = lazy(() => import('./routes/About/About'))
const Contact = lazy(() => import('./routes/Contact/Contact'))
const DFS101 = lazy(() => import('./routes/DFS101/DFS101'))
// const Login = lazy(() => import('./routes/Login/Login'))
const Privacy = lazy(() => import('./routes/Privacy/Privacy'))
const SupportUs = lazy(() => import('./routes/SupportUs/SupportUs'))

const NBAOptimizer = lazy(() => import('./routes/NBA/Optimizer/Optimizer'))
const NBAProps = lazy(() => import('./routes/NBA/Props/Props'))

const MLBOptimizer = lazy(() => import('./routes/MLB/Optimizer/Optimizer'))

const NFLOptimizer = lazy(() => import('./routes/NFL/Optimizer/Optimizer'))
const NFLNotes = lazy(() => import('./routes/NFL/Notes/Notes'))

const GOLFOptimizer = lazy(() => import('./routes/GOLF/Optimizer/Optimizer'))

const Plans = lazy(() => import('./routes/Plans/Plans'))
const User = lazy(() => import('./routes/User/User'))

const SubscriptionSuccessful = lazy(() => import('./routes/SubscriptionStatus/SubscriptionSuccessful'))
const SubscriptionNotSuccessful = lazy(() => import('./routes/SubscriptionStatus/SubscriptionNotSuccessful'))

// const history = createBrowserHistory()
// let history = useHistory()
// This does not solve the refresh problem, but will
// ensure when navigating to a new page that it's on
// the top.
// history.listen((location, action) => {
//   // window.scrollTo(0, 0);
//   console.log('History:', location)
// })

// const auth = new Auth();

// const handleAuthentication = ({location}) => {
//   if (/access_token|id_token|error/.test(location.hash)) {
//     auth.handleAuthentication();
//   }
// }

// if (window.location.hostname === "bluecollardfs.com") {
//   ReactGA.initialize('UA-92774808-1')
// }

//<Redirect to="/plans"/>

function usePageViews() {
  // let location = useLocation()

  // useEffect(
  //   () => {
  //     if (window.location.hostname === "bluecollardfs.com") {
  //       ReactGA.pageview(window.location.pathname)
  //     } else {
  //       console.log(`Would send GA for ${location.pathname} if not localhost`)
  //     }
  //   },
  //   [location]
  // )
}

function getSportsAccess(user) {
  var access = []
  // console.log('User inside of getSportsAccess: ', user)
  if (user) {
    // console.log('Inside if user!')
    var groups = user.signInUserSession.accessToken.payload["cognito:groups"]
    // console.log('Cognito groups: ', groups)

    if (groups.includes('Admin-Group')) {
      access = ['ADMIN', 'NBA', 'NFL', 'MLB', 'GOLF']
    } else if (groups.includes('All-Sports')) {
      access = ['NBA', 'NFL', 'MLB', 'GOLF']
    } else if (groups.includes('NBA-Access')) {
      // console.log('User has access to NBA')
      access.push('NBA')
    } else if (groups.includes('MLB-Access')) {
      // console.log('User has access to MLB')
      access.push('MLB')
    } else if (groups.includes('NFL-Access')) {
      // console.log('User has access to NFL')
      access.push('NFL')
    } else if (groups.includes('Props-Access')) {
      access.push('Props')
    } else if (groups.includes('GOLF-Access')) {
      access.push('GOLF')
    }
  }

  console.log('This user has access to: ', access)
  // console.log(user)
  return access
}

function App(props) {

  // Init isAuthenticated to false and set up userHasAuthenticated
  // to update isAuthenticated.
  const [isAuthenticated, userHasAuthenticated] = useState(false)
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const [user, setUser] = useState(null)
  const [sportsAccess, setSportsAccess] = useState([])

  let history = useHistory()

  // https://serverless-stack.com/chapters/load-the-state-from-the-session.html
  useEffect(() => {

    Hub.listen('auth', ({ payload: { event, data } }) => {
      let desiredUrl = ''
      switch (event) {
        case 'signIn':
          setIsAuthenticating(true);
          console.log('User signed in')
          break

        case 'signIn_failure':
          console.log('User failed to sign in. Error: ', data)
          break

        case 'signOut':
          console.log('User signed out')
          userHasAuthenticated(false)
          break

        case "customOAuthState":
          desiredUrl = decodeURIComponent(data).replace('slash', '/')
          console.log('Path the user was on when clicked login: ', desiredUrl)

          waitForAuthAndRedirect(desiredUrl)
          // const params = new URLSearchParams(location.search)
          // if (params.has('code')) {
          //   console.log('Found code in the params!')
          //   waitForAuthToComplete(desiredUrl)
          // }
          break

        default:
          break
      }

    })

    onLoad();
  }, []);


  async function waitForAuthAndRedirect(desiredUrl) {
    await Auth.currentAuthenticatedUser().then(currentUser => {
      // console.log('User:', currentUser);
      // this.setState({authState: 'signedIn'});
      // console.log('Pushing desired url')
      history.push(desiredUrl)
      userHasAuthenticated(true)
      setUser(currentUser)
      // if (window.location.hostname !== "localhost") {
      //   ReactGA.set({ userId: user.username });
      // }
      setSportsAccess(getSportsAccess(currentUser))
    }).catch(e => {
      console.log(e);
      // this.setState({authState: 'signIn'});
    });

    setIsAuthenticating(false);
  }

  async function onLoad() {

    await Auth.currentAuthenticatedUser().then(currentUser => {
      // console.log('User:', currentUser);
      userHasAuthenticated(true)
      setUser(currentUser)
      setSportsAccess(getSportsAccess(currentUser))

      console.log('onLoad current user: ', currentUser)
      if (window.location.hostname !== "localhost") {
        console.log(currentUser.signInUserSession.idToken.payload.email)
        ReactGA.initialize('G-NVW2JQMSZB', {
          gaOptions: {
            userId: currentUser ? currentUser.signInUserSession.idToken.payload.email : "n/a",
          }
        });
      } else {
        console.log('Would have initialized GA for:', currentUser.signInUserSession.idToken.payload.email, ' if not localhost')
      }


      // this.setState({authState: 'signedIn'});
    }).catch(e => {
      console.log(e);
      if (window.location.hostname !== "localhost") {
        ReactGA.initialize('G-NVW2JQMSZB')
      } else {
        console.log('Would have initiliazed GA for unlogged in user if not localhost')
      }
      // this.setState({authState: 'signIn'});
    });

    setIsAuthenticating(false);
  }

  // usePageViews()

  // Leverage Suspense per: https://reactjs.org/docs/code-splitting.html#route-based-code-splitting
  return (
    !isAuthenticating &&

    <Suspense fallback={ <Loading /> }>
      <>

          {/* Switch will only render the first matched <Route/> child */}
          <Switch>

            {/* Routes just for Sharp Sports Network */}
            {/* <Route exact path="/nba-optimizer-sharpsportsnet">
              <NBAOptimizer
                basic={false} // will show donate button
                user={user}
                sportsAccess={sportsAccess}
              />
            </Route>

            <Route exact path="/nfl-optimizer-sharpsportsnet">
              <NFLOptimizer
                basic={false} // will show donate button
                user={user}
                sportsAccess={sportsAccess}
              />
            </Route> */}

            {/* Normal BCDFS Routes below */}
            <Route exact path="/">
              <NavBar
                isAuthenticated={isAuthenticated}
                userHasAuthenticated={userHasAuthenticated}
                user={user}
              />
              <Home/>
              <Footer />
            </Route>

            {/* NBA */}
            <Route exact path="/nba-optimizer">
              <NavBar
                isAuthenticated={isAuthenticated}
                userHasAuthenticated={userHasAuthenticated}
                user={user}
              />
              <NBAOptimizer
                // TODO -- what are we passing user for? Was this
                // leftover from when we were trying to calculate
                // the sport access at a lower level?
                user={user}
                sportsAccess={sportsAccess}
              />
              <Footer />
            </Route>

            {
              sportsAccess && (sportsAccess.includes('ADMIN') || sportsAccess.includes('Props'))
              &&
              <Route exact path="/nba-props">
                <NavBar
                  isAuthenticated={isAuthenticated}
                  userHasAuthenticated={userHasAuthenticated}
                  user={user}
                />

                <AuthenticatedRoute
                  component={NBAProps}
                  isAuthenticated={isAuthenticated}
                />
                <Footer />
              </Route>
            }

              {/* {
              <Route exact path="/nba-props">
                <NavBar
                  isAuthenticated={isAuthenticated}
                  userHasAuthenticated={userHasAuthenticated}
                  user={user}
                />

                <NBAProps/>

                <Footer />
              </Route>
            } */}

            {/* MLB */}
            <Route exact path="/mlb-optimizer">
              <NavBar
                isAuthenticated={isAuthenticated}
                userHasAuthenticated={userHasAuthenticated}
                user={user}
              />
              <MLBOptimizer
                user={user}
                sportsAccess={sportsAccess}
              />
              <Footer />
            </Route>

            {/* <Route path="/mlb-lineups">
              <NavBar />
              <LineupsPage />
              <Footer />
            </Route> */}

            {/* NFL */}
            <Route exact path="/nfl-optimizer">
              <NavBar
                isAuthenticated={isAuthenticated}
                userHasAuthenticated={userHasAuthenticated}
                user={user}
              />
              <NFLOptimizer
                user={user}
                sportsAccess={sportsAccess}
              />
              <Footer />
            </Route>

            <Route exact path="/nfl-optimizer-notes">
              <NavBar
                isAuthenticated={isAuthenticated}
                userHasAuthenticated={userHasAuthenticated}
                user={user}
              />
              <NFLNotes />
              <Footer />
            </Route>

            {/* GOLF */}
            <Route exact path="/golf-optimizer">
              <NavBar
                isAuthenticated={isAuthenticated}
                userHasAuthenticated={userHasAuthenticated}
                user={user}
              />
              <GOLFOptimizer
                user={user}
                sportsAccess={sportsAccess}
              />
              <Footer />
            </Route>

            {/* Other */}

            <Route exact path="/about">
              <NavBar
                isAuthenticated={isAuthenticated}
                userHasAuthenticated={userHasAuthenticated}
                user={user}
              />
              <About />
              <Footer />
            </Route>

            {/* <Route path="/articles">
              <Articles />
            </Route> */}

            <Route exact path="/contact">
              <NavBar
                isAuthenticated={isAuthenticated}
                userHasAuthenticated={userHasAuthenticated}
                user={user}
              />
              <Contact />
              <Footer />
            </Route>

            <Route exact path="/dfs_intro">
              <NavBar
                isAuthenticated={isAuthenticated}
                userHasAuthenticated={userHasAuthenticated}
                user={user}
              />
              <DFS101 />
              <Footer />
            </Route>

            {/* <Route path="/login">
              <NavBar/>
              <div className="bg-light-gray flex-grow-1">
              </div>
              <Footer />
            </Route> */}

            {/* <Route path="/logintest">
              <NavBar
                isAuthenticated={isAuthenticated}
                userHasAuthenticated={userHasAuthenticated}
              />
              <div className="bg-light-gray flex-grow-1">
                <LoginTest
                  isAuthenticated={isAuthenticated}
                  userHasAuthenticated={userHasAuthenticated}
                  // historyProp={props.history}
                />
              </div>
              <Footer />
            </Route> */}

            <Route exact path="/privacy_policy">
              <NavBar
                isAuthenticated={isAuthenticated}
                userHasAuthenticated={userHasAuthenticated}
                user={user}
              />
              <Privacy />
              <Footer />
            </Route>

            <Route exact path="/support_us">
              <NavBar
                isAuthenticated={isAuthenticated}
                userHasAuthenticated={userHasAuthenticated}
                user={user}
              />
              <SupportUs />
              <Footer />
            </Route>

            <Route exact path="/plans">
              <NavBar
                isAuthenticated={isAuthenticated}
                userHasAuthenticated={userHasAuthenticated}
                user={user}
              />
              <Plans />
              <Footer />
            </Route>

            <Route exact path="/user">
              <NavBar
                isAuthenticated={isAuthenticated}
                userHasAuthenticated={userHasAuthenticated}
                user={user}
              />
              <User
                isAuthenticated={isAuthenticated}
                userHasAuthenticated={userHasAuthenticated}
                user={user}
                sportsAccess={sportsAccess}
              />
              <Footer />
            </Route>

            <Route exact path="/subscription_not_successful">
              <NavBar
                isAuthenticated={isAuthenticated}
                userHasAuthenticated={userHasAuthenticated}
                user={user}
              />
              <SubscriptionNotSuccessful />
              <Footer />
            </Route>


            <Route exact path="/subscription_successful">
              <NavBar
                isAuthenticated={isAuthenticated}
                userHasAuthenticated={userHasAuthenticated}
                user={user}
              />
              <SubscriptionSuccessful />
              <Footer />
            </Route>

            {/* Catch all unmatched routes, has to be last */}
            <Route>
              <NavBar
                isAuthenticated={isAuthenticated}
                userHasAuthenticated={userHasAuthenticated}
                user={user}
              />
              <NotFound />
              <Footer />
            </Route>

          </Switch>

        </>

    </Suspense>
  )
}

export default App;
